import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>Ayser World</h1>
      <p>
        Here's a coffee icon: <FontAwesomeIcon icon={faCoffee} />
      </p>
      <p>
        Follow me on Twitter: <FontAwesomeIcon icon={faTwitter} />
      </p>
    </div>
  );
}

export default App;
